import React from "react"

const ThreeArrows = () => (
    <svg width="533" height="494" viewBox="0 0 533 494" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of three hands pushing arrows in one clear direction</desc>
        <path d="M18.7122 133.936C25.2378 99.6303 51.3403 69.7988 84.3413 58.7985C105.969 51.527 130.021 52.4593 151.276 60.4765C173.463 68.8666 188.379 84.1552 203.294 101.868C213.362 113.987 224.922 126.106 240.211 130.208C249.347 132.631 259.042 131.886 268.551 130.58C303.416 125.173 333.993 115.292 369.232 124.801C394.216 131.513 417.149 146.428 432.437 167.31C460.404 205.345 459.286 259.415 422.556 291.297C402.233 309.01 380.978 309.196 355.248 310.688C342.011 311.434 327.281 315.535 320.569 326.909C313.484 338.655 317.213 353.943 322.434 366.622C327.654 379.3 334.366 392.538 332.688 406.148C328.773 437.472 292.602 446.607 266.686 449.591C231.821 453.879 186.514 456.676 159.666 429.454C142.886 412.301 137.665 387.131 135.801 363.079C132.258 318.146 130.58 270.975 85.8329 247.11C75.2055 241.516 63.4593 237.974 53.5776 231.262C24.6784 211.685 12.373 166.751 18.7122 133.936Z" fill="#eff1f6"/>
        <path d="M234.618 253.076C239.56 253.076 243.567 249.069 243.567 244.126C243.567 239.184 239.56 235.177 234.618 235.177C229.675 235.177 225.668 239.184 225.668 244.126C225.668 249.069 229.675 253.076 234.618 253.076Z" fill="#272223"/>
        <path d="M35.6798 56.0024L64.579 25.2387L41.0867 1.37354L1.37354 43.5105L26.3574 65.5112L26.5438 65.6976L35.6798 56.0024Z" fill="#61D0EC"/>
        <path d="M145.496 176.26L105.037 144.377C107.834 142.513 107.834 138.225 107.834 129.835C107.834 117.716 107.088 102.241 109.885 92.5453C112.868 82.8501 120.14 81.7314 126.479 88.4435C127.97 90.1215 132.631 98.1387 140.649 106.342L174.209 147.361L193.227 128.157L204.973 206.651L126.292 195.65L145.496 176.26Z" fill="#61D0EC"/>
        <path d="M108.02 129.835C108.02 138.225 108.02 142.513 105.224 144.378C103.919 145.31 102.054 145.683 99.4438 145.683C90.8673 145.683 90.1215 131.14 90.1215 131.14C80.7991 129.835 80.0534 124.614 80.0534 124.614C66.4428 121.258 62.7139 111.749 62.7139 111.749C61.9681 111.376 61.0358 111.004 60.29 110.631C56.0017 108.207 53.7644 104.292 51.1541 100.376C48.5439 96.6472 46.8659 92.5454 45.7472 88.0706C44.4421 83.4095 43.5098 78.5619 41.086 74.2736C36.7978 66.6293 35.8655 68.1209 30.0857 61.5952L30.2721 61.7817L37.9164 53.578L59.1713 30.8315C63.4596 34.0011 79.3076 40.3403 84.3416 41.0861C88.8163 41.8319 93.664 42.9506 97.2065 45.9337C102.241 50.0356 106.529 54.5103 112.309 57.4934C120.885 61.7817 122.75 66.8157 126.292 70.9176C129.835 75.0194 140.276 78.0026 140.462 84.7146C140.649 91.6132 141.021 106.156 141.021 106.156C133.004 98.1388 128.343 89.9351 126.851 88.2571C120.699 81.545 113.241 82.6637 110.258 92.3589C107.275 102.241 108.02 117.716 108.02 129.835Z" fill="white"/>
        <path d="M38.6621 42.0183C39.5943 42.7641 39.5943 44.2556 38.6621 45.1879C37.9163 46.1201 36.4247 46.1201 35.4925 45.3743C34.5603 44.6285 34.5603 43.137 35.306 42.2047C36.4247 41.2725 37.7298 41.0861 38.6621 42.0183Z" fill="#272223"/>
        <path d="M31.3907 35.1198C32.3229 36.052 32.3229 37.3571 31.5771 38.2894C30.8313 39.2216 29.3397 39.2216 28.4075 38.2894C27.4753 37.5436 27.4753 36.052 28.2211 35.1198C29.1533 34.374 30.4584 34.374 31.3907 35.1198Z" fill="#272223"/>
        <path d="M30.8315 62.5275C36.6114 69.0531 36.7978 66.8158 41.0861 74.4601C43.5099 78.7483 44.4421 83.5959 45.7473 88.2571C47.0524 92.7318 48.544 96.6472 51.1542 100.563C53.9509 104.478 56.0018 108.393 60.2901 110.817C61.0359 111.19 61.7817 111.563 62.7139 111.936C62.7139 111.936 66.4428 121.258 80.0534 124.801C80.0534 124.801 80.7992 130.021 90.1215 131.326C90.1215 131.326 91.0538 145.869 99.4439 145.869C102.054 145.869 103.919 145.496 105.224 144.564C108.02 142.7 108.02 138.411 108.02 130.021C108.02 117.902 107.275 102.427 110.071 92.7318C113.055 83.0366 120.326 81.9179 126.665 88.63C128.157 90.308 132.818 98.3252 140.835 106.529" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M105.223 144.377L145.496 176.26" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.496 176.26L126.479 195.464L205.159 206.464L193.413 127.97L174.396 147.174L140.835 106.156C140.835 106.156 140.462 91.613 140.276 84.7145C140.089 77.816 129.648 74.8328 126.106 70.9175C122.563 66.8156 120.512 61.7816 112.122 57.4933C106.343 54.5102 102.054 50.0355 97.0202 45.9336C93.4777 42.9505 88.8166 42.0182 84.1554 41.086C79.1213 40.1538 63.0869 34.9333 58.9851 31.7637" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.515 151.835L192.108 188.006" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M177.192 192.853L149.784 188.752" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.7647 25.0516L37.73 53.7644L30.0857 61.968L26.1703 65.8834L1 43.6963L41.4589 1L64.7647 25.0516Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.7299 53.7644L20.9497 38.1029" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.869 166.378L122.936 148.852" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M163.022 148.852L124.427 100.376" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M91.9858 54.3239C97.2063 57.8663 102.427 61.2224 107.647 64.7648" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M483.897 134.868L500.49 173.649L531.813 161.717L508.881 108.393L478.676 122.563L478.49 122.749L483.897 134.868Z" fill="#61D0EC"/>
        <path d="M329.705 187.633L375.012 163.395C375.571 166.751 379.486 168.429 387.131 171.785C398.131 176.633 412.674 182.04 420.505 188.565C428.335 195.091 426.285 202.362 417.708 205.345C415.657 206.091 406.335 207.21 395.707 211.312L344.807 225.668L354.875 250.652L278.246 230.143L319.637 162.463L329.705 187.633Z" fill="#61D0EC"/>
        <path d="M387.317 171.785C379.673 168.429 375.757 166.751 375.198 163.395C374.825 161.903 375.384 160.039 376.317 157.615C379.673 149.784 393.283 154.818 393.283 154.818C398.131 146.801 403.165 148.293 403.165 148.293C411.741 137.292 421.81 137.479 421.81 137.479C422.369 136.92 423.115 136.174 423.86 135.801C427.776 132.818 432.251 132.445 436.912 131.513C441.386 130.58 445.675 130.767 450.336 131.326C455.183 132.072 460.031 133.004 464.879 132.631C473.642 131.886 472.709 130.394 480.913 127.597H480.727L485.201 137.852L497.507 166.378C492.846 168.988 480.727 181.107 477.93 185.396C475.32 189.311 472.523 193.04 468.421 195.277C462.641 198.261 456.861 200.311 451.827 204.413C444.556 210.566 439.149 210.38 433.929 212.058C428.708 213.736 421.996 222.126 415.657 219.515C409.318 216.905 395.893 211.498 395.893 211.498C406.521 207.396 415.843 206.278 417.894 205.532C426.657 202.549 428.522 195.277 420.691 188.752C412.86 182.04 398.317 176.633 387.317 171.785Z" fill="white"/>
        <path d="M495.456 143.259C494.337 143.818 493.032 143.259 492.659 142.14C492.1 141.021 492.659 139.716 493.778 139.343C494.897 138.784 496.202 139.343 496.761 140.462C497.321 141.581 496.575 142.886 495.456 143.259Z" fill="#272223"/>
        <path d="M504.592 139.343C503.474 139.903 502.168 139.343 501.609 138.225C501.05 137.106 501.609 135.801 502.728 135.428C503.846 134.869 505.152 135.428 505.525 136.547C506.27 137.479 505.711 138.784 504.592 139.343Z" fill="#272223"/>
        <path d="M479.795 127.784C471.591 130.394 473.455 131.513 464.692 132.259C459.845 132.631 454.997 131.699 450.15 130.953C445.675 130.394 441.2 130.208 436.725 131.14C432.064 132.072 427.59 132.445 423.674 135.428C422.928 135.987 422.369 136.547 421.623 137.106C421.623 137.106 411.555 136.92 402.979 147.92C402.979 147.92 397.945 146.615 393.097 154.446C393.097 154.446 379.3 149.412 376.13 157.242C375.012 159.666 374.639 161.531 375.012 163.022C375.571 166.378 379.486 168.056 387.131 171.412C398.131 176.26 412.674 181.667 420.505 188.193C428.335 194.718 426.284 201.99 417.708 204.973C415.657 205.719 406.335 206.837 395.707 210.939" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M375.012 163.395L329.706 187.633" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M329.705 187.633L319.637 162.463L278.246 230.143L354.875 250.652L344.807 225.668L395.707 211.312C395.707 211.312 409.131 216.719 415.471 219.329C421.81 221.939 428.708 213.549 433.742 211.871C438.963 210.193 444.183 210.379 451.641 204.227C456.675 200.125 462.642 197.887 468.235 195.091C472.337 193.04 475.133 189.125 477.744 185.209C480.54 180.921 491.727 168.429 496.202 165.819" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M335.858 234.99L300.247 225.668" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M301.925 210.193L316.467 186.701" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M500.49 173.836L485.015 137.852L480.727 127.597L478.49 122.19L508.881 108.02L532 162.09L500.49 173.836Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M485.016 137.852L506.084 128.716" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M338.655 191.921L363.825 177.938" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M347.791 214.668L407.64 198.447" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M409.505 167.683C417.149 170.293 424.047 174.955 429.268 180.921" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M462.828 187.074C457.608 190.43 452.387 193.972 446.98 197.328" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.1378 457.235L43.6961 430.387L21.3225 455.184L66.0697 492.287L86.3924 465.625H86.5788L76.1378 457.235Z" fill="#61D0EC"/>
        <path d="M188.939 339.401L159.853 381.91C157.802 379.3 153.514 379.487 145.124 380.046C133.005 380.792 117.716 382.656 107.648 380.419C97.7661 378.182 96.0881 370.91 102.427 364.198C103.919 362.52 111.75 357.3 119.394 348.909L157.988 312.739L137.479 295.026L215.041 278.06L209.261 357.3L188.939 339.401Z" fill="#61D0EC"/>
        <path d="M145.123 380.046C153.513 379.487 157.801 379.3 159.852 381.91C160.785 383.029 161.344 384.894 161.53 387.504C162.276 395.894 147.733 397.758 147.733 397.758C146.987 407.081 141.953 408.199 141.953 408.199C139.53 421.997 130.394 426.471 130.394 426.471C130.207 427.403 129.834 428.149 129.461 428.895C127.224 433.37 123.495 435.794 119.766 438.777C116.224 441.76 112.308 443.624 108.02 445.116C103.359 446.794 98.6978 448.099 94.596 450.709C87.1381 455.37 88.6297 456.303 82.6634 462.455L82.8498 462.269L74.2733 455.184L50.2217 435.607C53.0184 431.132 58.2389 414.725 58.7982 409.691C59.3576 405.03 60.1034 400.369 62.7136 396.64C66.4426 391.419 70.7308 386.758 73.341 380.792C77.07 372.029 81.9176 369.791 85.6465 365.876C89.3755 361.961 91.6128 351.333 98.5113 350.774C105.41 350.215 119.766 348.723 119.766 348.723C112.308 357.3 104.478 362.52 102.8 364.012C96.4605 370.724 98.1385 377.995 108.02 380.232C117.529 382.656 133.004 380.792 145.123 380.046Z" fill="white"/>
        <path d="M61.968 454.998C62.7138 454.065 64.2054 453.879 65.1376 454.625C66.0698 455.37 66.2563 456.862 65.5105 457.794C64.7647 458.726 63.2731 458.913 62.3409 458.167C61.4087 457.421 61.2222 455.93 61.968 454.998Z" fill="#272223"/>
        <path d="M55.8151 462.828C56.5609 461.896 58.0525 461.71 58.9847 462.455C59.917 463.201 60.1034 464.693 59.3576 465.625C58.6118 466.557 57.1203 466.744 56.188 465.998C55.2558 465.252 54.8829 463.76 55.8151 462.828Z" fill="#272223"/>
        <path d="M83.0364 461.523C89.0027 455.37 86.9518 455.37 94.2232 450.523C98.325 447.913 103.173 446.607 107.647 444.929C111.936 443.438 115.851 441.573 119.393 438.59C123.122 435.607 126.851 433.183 129.089 428.709C129.462 427.963 129.834 427.031 130.021 426.285C130.021 426.285 139.157 421.81 141.581 408.013C141.581 408.013 146.615 406.894 147.36 397.572C147.36 397.572 161.903 395.708 161.157 387.317C160.971 384.707 160.412 382.843 159.479 381.724C157.429 379.114 153.14 379.3 144.75 379.86C132.631 380.605 117.343 382.47 107.274 380.232C97.3928 377.995 95.7147 370.724 102.054 364.012C103.545 362.334 111.376 357.113 119.021 348.723" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.852 381.91L188.938 339.401" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.938 339.401L209.447 357.113L215.227 277.873L137.665 294.84L158.174 312.552L119.58 348.723C119.58 348.723 105.037 350.028 98.325 350.774C91.4265 351.333 89.1892 361.961 85.4602 365.876C81.7313 369.791 76.6972 372.029 73.1547 380.792C70.5445 386.758 66.2562 391.419 62.5273 396.64C59.9171 400.369 58.9848 405.03 58.6119 409.691C58.0526 414.725 53.9508 430.946 51.1541 435.421" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M161.717 300.247L197.515 292.229" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.295 306.772L201.057 334.367" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.5098 430.2L73.9006 455.184L82.4771 462.269L86.7654 465.998L66.2563 492.473L20.9497 454.997L43.5098 430.2Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.9005 455.184L59.1711 472.896" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M178.87 339.773L163.022 363.825" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M160.412 323.926L114.732 365.69" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M127.225 393.843C119.207 394.962 111.004 394.03 103.546 391.233" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.731 401.115C73.9005 395.708 76.8837 390.301 80.0533 384.707" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ThreeArrows