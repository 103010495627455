import React from "react"

const Quotes = () => (
    <svg width="75" height="65" viewBox="0 0 75 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <desc>Quote mark icon used for testimonials</desc>
        <path d="M1.8195 38.7563L1.81964 38.7553C3.98608 24.3769 12.7716 12.4284 22.805 2.06401C23.6312 1.22085 24.5155 0.954293 25.3253 1.01079C26.164 1.0693 27.004 1.48232 27.6624 2.13902C28.3205 2.79553 28.7443 3.64324 28.8149 4.49821C28.8834 5.32665 28.6279 6.22635 27.8096 7.06572L27.8084 7.06688C20.024 15.0775 12.6965 24.1529 9.72368 35.387L9.22673 37.265L11.0439 36.5783C14.5499 35.2534 18.7884 37.2246 21.211 40.2784C24.4428 44.3528 24.5966 49.3701 22.7029 53.7673C20.8011 58.1832 16.8665 61.8773 12.0877 63.201C9.15463 64.0135 7.11804 63.3839 5.6266 62.0074C4.06586 60.567 2.98317 58.1972 2.29589 55.3274C0.925272 49.6045 1.25772 42.4608 1.8195 38.7563Z" fill="#FFFFFF" stroke="#272223" strokeWidth="2"/>
        <path d="M55.1032 39.8365L54.8252 41.8904L56.6059 40.8297C58.9405 39.4389 61.7021 38.9437 64.3755 39.4363C67.049 39.929 69.4514 41.376 71.1351 43.507L71.1402 43.5134L71.1453 43.5197C73.6456 46.5801 73.9268 49.7703 72.8868 52.717C71.827 55.7197 69.3676 58.5233 66.3057 60.5855C63.2448 62.647 59.6955 63.8905 56.5665 63.8907C53.4748 63.891 50.8588 62.6926 49.3599 59.9115L49.3597 59.911C47.4814 56.4303 46.9887 51.6128 47.2236 46.5773C47.4571 41.5731 48.4002 36.5005 49.2663 32.6034C51.5348 22.8646 55.9756 13.7629 62.2575 5.97704L62.2576 5.9771L62.2657 5.9667C62.9894 5.04511 63.7709 4.75733 64.4698 4.77727C65.2086 4.79835 66.0001 5.16974 66.6607 5.83193C67.9958 7.17022 68.5015 9.34943 67.1132 11.13C60.9215 19.0063 56.8675 28.344 55.3427 38.2414C55.2617 38.7111 55.1819 39.2545 55.1032 39.8365Z" fill="#FFFFFF" stroke="#272223" strokeWidth="2"/>
    </svg>
)

export default Quotes