import React from "react"

const Magnify = () => (
    <svg width="533" height="506" viewBox="0 0 533 506" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a hand holding a magnifying glass</desc>
        <path d="M405.36 326.331C404.728 301.044 414.834 276.704 426.204 254.261C437.573 231.818 450.837 209.692 457.785 185.352C476.418 121.501 445.468 46.5855 387.358 14.3436C358.935 -1.1451 322.616 -6.51874 295.14 10.5505C255.348 35.2061 251.558 92.1035 220.608 127.19C200.396 149.949 170.393 161.329 140.707 169.231C111.336 176.818 80.7017 182.191 53.5416 196.099C26.3815 210.008 2.37956 235.295 0.168855 265.641C-2.67348 304.521 30.8029 338.027 67.7533 350.039C104.704 362.05 144.496 358.257 183.342 357.309C222.187 356.361 263.243 358.889 295.14 381.016C306.194 388.919 315.668 398.718 322.932 410.097C334.933 429.063 340.618 451.506 353.882 469.839C376.936 501.765 422.414 514.725 458.732 499.869C476.418 492.598 491.577 479.006 498.841 461.621C506.105 443.919 503.894 422.425 491.893 407.884C477.05 390.183 455.574 395.873 436.941 386.39C416.413 375.643 405.675 348.142 405.36 326.331Z" fill="#eff1f6"/>
        <path d="M322.731 260.038L304.767 278.002L291.013 263.968C292.417 262.845 293.539 261.722 294.943 260.6C296.908 258.915 298.592 257.231 300.556 255.267C302.241 253.582 304.205 251.618 305.889 249.653C307.012 248.249 308.135 247.127 309.258 245.723L322.731 260.038Z" fill="white"/>
        <path d="M343.501 269.862L314.871 298.492L302.241 285.861C300.837 284.458 300.837 282.212 302.241 280.809L305.047 278.002L323.011 260.038L325.818 257.231C327.222 255.828 329.467 255.828 330.871 257.231L343.501 269.862Z" fill="white"/>
        <path d="M352.483 356.875L320.485 324.877L311.222 315.333C307.854 311.965 307.854 306.071 311.222 302.703L334.239 279.686L347.712 266.213C351.08 262.845 356.974 262.845 360.342 266.213L375.499 281.37C375.499 283.055 375.499 284.458 375.219 286.423C374.657 305.509 374.938 329.648 369.886 344.805C366.518 355.752 359.5 359.401 352.483 356.875Z" fill="#61D0EC"/>
        <path d="M484.687 423.959C483.283 422.555 481.038 422.555 479.915 423.959C478.512 425.362 478.512 427.608 479.915 428.73C481.318 430.134 483.564 430.134 484.687 428.73C485.809 427.327 486.09 425.082 484.687 423.959ZM481.599 409.644L493.949 397.294L494.23 397.574L531 434.625L480.476 485.148L443.426 448.098L481.599 409.644ZM495.353 434.625C493.949 433.221 491.704 433.221 490.581 434.625C489.178 436.028 489.178 438.274 490.581 439.396C491.984 440.8 494.23 440.8 495.633 439.396C496.756 438.274 496.756 436.028 495.353 434.625Z" fill="#61D0EC"/>
        <path d="M493.949 397.294L481.599 409.644L443.426 447.817C436.97 442.484 415.919 428.45 408.059 426.766C401.042 425.362 393.744 423.397 388.411 418.626C380.833 411.889 374.377 404.591 365.395 399.539C352.203 392.241 349.396 384.382 344.063 377.926C338.73 371.47 322.45 366.137 322.45 355.471C322.45 344.805 320.766 325.157 320.766 325.157C332.836 338.349 341.818 347.893 344.063 350.7C346.87 353.787 349.677 356.033 352.764 357.156C359.781 359.682 366.518 355.752 370.167 345.086C375.219 329.929 374.658 305.509 375.5 286.703C375.5 285.019 375.5 283.335 375.781 281.651C376.342 266.494 377.745 262.003 390.095 262.284C403.568 262.564 404.13 285.581 404.13 285.581C418.445 288.107 419.287 296.247 419.287 296.247C440.338 302.141 445.952 317.298 445.952 317.298C447.355 317.859 448.478 318.421 449.601 319.263C456.057 323.473 459.425 329.648 463.354 335.823C467.284 341.718 469.53 348.174 471.214 355.191C472.898 362.489 474.301 370.348 477.669 377.084C483.845 389.154 485.529 387.189 493.949 397.294Z" fill="white"/>
        <path d="M495.352 434.625C496.756 436.028 496.756 438.274 495.352 439.396C493.949 440.8 491.704 440.8 490.3 439.396C488.897 437.993 488.897 435.748 490.3 434.625C491.984 433.221 494.23 433.221 495.352 434.625Z" fill="#272223"/>
        <path d="M484.687 423.959C486.09 425.362 486.09 427.608 484.687 428.73C483.283 429.853 481.038 430.134 479.915 428.73C478.511 427.327 478.511 425.082 479.915 423.959C481.038 422.555 483.283 422.555 484.687 423.959Z" fill="#272223"/>
        <path d="M122.041 77.0312C163.863 35.209 231.508 35.209 273.33 77.0312C273.892 77.5926 274.453 78.1539 274.734 78.4346C276.137 79.838 277.26 80.9608 278.383 82.3642C314.591 124.467 312.626 187.902 272.769 227.76C230.947 269.582 163.302 269.582 121.479 227.76C120.918 227.198 120.076 226.356 119.514 225.795C117.83 223.83 116.146 222.146 114.462 220.181C80.4992 178.359 82.7446 116.327 122.041 77.0312Z" fill="white"/>
        <path d="M122.041 228.321C163.863 270.143 231.508 270.143 273.33 228.321C313.188 188.463 315.152 124.467 278.383 82.6448C277.26 81.2414 276.137 79.838 274.734 78.7153C274.172 78.1539 273.33 77.3118 272.769 76.7505C230.947 34.9283 163.301 34.9283 121.479 76.7505C82.1832 116.046 79.9377 178.359 114.462 220.462C116.146 222.426 117.83 224.391 119.514 226.075C120.356 226.917 121.199 227.759 122.041 228.321ZM309.819 60.7514C354.729 115.766 353.326 196.323 305.048 249.653C303.364 251.618 301.68 253.302 299.715 255.267C297.75 256.951 296.066 258.916 294.101 260.6C237.122 311.965 149.267 310.281 94.2527 255.267C93.4106 254.425 92.5686 253.583 91.7265 252.46C38.1155 195.481 38.9576 105.942 94.8141 50.0853C151.513 -6.61323 243.578 -6.61323 300.276 50.0853C301.118 50.9274 301.96 51.7694 302.802 52.8922C305.328 55.4183 307.855 57.9445 309.819 60.7514Z" fill="white"/>
        <path d="M291.013 263.968C234.034 311.965 148.706 309.158 94.8139 255.267C94.8139 255.267 94.5332 254.986 94.5332 254.705C149.548 309.72 237.402 311.404 294.382 260.038C296.346 258.354 298.03 256.67 299.995 254.705C301.679 253.021 303.644 251.056 305.328 249.092C353.326 195.761 355.01 115.485 310.1 60.19C354.448 114.082 354.168 192.393 309.258 245.723C308.135 247.127 307.012 248.25 305.89 249.653C304.205 251.618 302.521 253.302 300.557 255.267C298.592 257.231 296.908 258.916 294.943 260.6C293.539 262.003 292.417 263.126 291.013 263.968Z" fill="white"/>
        <path d="M273.33 228.321C231.508 270.143 163.863 270.143 122.04 228.321C121.198 227.479 120.356 226.637 119.795 225.795C120.356 226.356 121.198 227.198 121.76 227.759C163.582 269.582 231.227 269.582 273.049 227.759C312.907 187.902 314.872 124.467 278.663 82.3641C315.152 124.748 313.187 188.463 273.33 228.321Z" fill="#BF6882"/>
        <path d="M121.479 76.7504C163.302 34.9282 230.947 34.9282 272.769 76.7504C273.33 77.3118 274.172 78.1538 274.734 78.7152C274.172 78.1538 273.611 77.5925 273.33 77.3118C231.508 35.4896 163.863 35.4896 122.041 77.3118C82.7447 116.327 80.4993 178.359 114.743 220.462C79.9379 178.359 82.1834 116.046 121.479 76.7504Z" fill="white"/>
        <path d="M94.5336 254.986C149.548 310 237.403 311.684 294.382 260.319C296.347 258.635 298.031 256.951 299.996 254.986C301.68 253.302 303.645 251.337 305.329 249.372C353.326 196.042 355.01 115.766 310.1 60.4706C307.855 57.9445 305.609 55.1376 303.083 52.6114C302.241 51.4887 301.118 50.366 299.996 49.5239C243.297 -7.17463 151.232 -7.17463 94.5336 49.5239C38.6771 105.38 37.835 195.48 92.0074 252.179C92.8495 253.302 93.6915 254.144 94.5336 254.986Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M274.734 78.7152C274.172 78.1538 273.33 77.3118 272.769 76.7504C230.947 34.9282 163.302 34.9282 121.479 76.7504C82.1834 116.046 79.9379 178.359 114.462 220.462C116.146 222.426 117.83 224.391 119.515 226.075C120.076 226.637 120.918 227.479 121.479 228.04C163.302 269.862 230.947 269.862 272.769 228.04C312.626 188.183 314.591 124.748 278.383 82.6448C277.26 81.2414 276.137 80.1186 274.734 78.7152Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M304.768 278.002L301.961 280.809C300.557 282.212 300.557 284.458 301.961 285.861L314.592 298.492L343.222 269.862L330.591 257.231C329.187 255.828 326.942 255.828 325.538 257.231L322.732 260.038" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M375.5 281.37L360.343 266.213C356.975 262.845 351.08 262.845 347.712 266.213" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M348.835 265.371L347.993 266.213L334.52 279.686L311.504 302.702C307.855 306.071 307.855 311.965 311.504 315.333L320.766 324.877L352.765 356.875" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M308.697 246.004H308.978L322.732 260.038L304.768 278.002L291.014 263.968H290.733" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M133.268 260.038C106.041 246.004 84.9899 220.462 75.7273 191.27C66.4647 162.079 69.5523 129.239 83.8672 102.012" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M314.029 112.678C328.625 142.431 326.379 179.762 308.696 207.831" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M493.949 397.294C485.529 387.189 483.845 389.154 477.669 377.084C474.301 370.348 472.898 362.489 471.214 355.191C469.53 348.174 467.284 341.718 463.354 335.823C459.144 329.648 456.057 323.193 449.601 319.263C448.478 318.421 447.075 317.859 445.952 317.298C445.952 317.298 440.619 302.422 419.287 296.247C419.287 296.247 418.445 288.107 404.13 285.581C404.13 285.581 403.568 262.564 390.095 262.284C378.026 262.003 376.623 266.494 375.781 281.651C375.781 283.335 375.781 284.739 375.5 286.703C374.938 305.79 375.219 329.929 370.167 345.086C366.518 355.752 359.781 359.682 352.764 357.156C349.957 356.033 346.87 354.068 344.063 350.7C341.818 347.893 332.836 338.349 320.766 325.157" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M320.766 324.877C320.766 324.877 322.45 344.525 322.45 355.191C322.45 365.857 338.449 371.19 344.063 377.646C349.396 384.101 352.203 392.241 365.395 399.258C374.377 404.03 380.833 411.609 388.411 418.345C393.744 423.117 401.042 425.081 408.059 426.485C415.919 428.169 436.97 442.484 443.426 447.536" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M443.426 447.817L481.599 409.644L493.949 397.294L494.23 397.574L531 434.344L480.476 484.868L443.426 447.817Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M481.6 409.644L507.142 435.186" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M304.767 278.002L322.45 260.319" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M356.974 368.383C363.991 371.19 372.412 367.541 377.184 361.646C381.955 355.752 383.92 348.174 385.323 340.876C386.727 333.578 387.569 325.719 390.656 318.982" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Magnify