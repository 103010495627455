import React from "react"

const Illumination = () => (
    <svg width="234" height="231" viewBox="0 0 234 231" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a street light illuminating a street with a hand leaning on the pole to keep a person from falling.</desc>
        <path d="M0.999912 74.8C4.89991 59.1 20.5999 47.1 36.7999 47.7C46.9999 48.1 56.3999 52.7 65.8999 56.5C75.3999 60.3 85.8999 63.2 95.5999 60.1C107.8 56.2 115.2 44 121 32.5C126.8 21 133.1 8.6 144.7 3C152.9 -0.900005 162.6 -0.600008 171.2 2.19999C179.8 4.99999 187.6 9.99999 194.8 15.4C210.7 27.1 225.7 41.7 231.6 60.5C237.5 79.3 231.5 103 213.9 112C208.7 114.6 202.9 115.9 197.6 118C192.2 120.2 186.9 123.6 184.7 129C182.2 134.9 184 141.8 186.9 147.5C189.8 153.2 193.8 158.4 196.1 164.4C201 177.6 196 193.8 184.5 201.8C173 209.8 156 209 145.4 199.8C141.2 196.2 138 191.6 134 187.7C130 183.9 124.7 180.8 119.2 181.6C110.5 182.8 106 192.1 100.4 198.8C90.2999 210.8 72.3999 215.7 57.5999 210.3C42.7999 205 32.0999 189.9 31.9999 174.1C31.8999 166.4 34.0999 159 35.6999 151.4C37.1999 143.9 37.9999 135.9 35.1999 128.7C30.8999 117.7 17.8999 115.8 10.3999 107.8C2.09991 99.3 -1.80009 86.5 0.999912 74.8Z" fill="#eff1f6"/>
        <path d="M178.8 48.9V49.1C177.8 49 176.7 48.9 175.6 48.9C174.2 48.9 172.8 49 171.4 49.2V48.9V38.5C171.4 33.8 167.6 29.9 162.8 29.9H117.9C104.4 29.9 93.4998 40.8 93.4998 54.3V229.9H85.5999V53.3C85.5999 36.2 99.3998 22.4 116.5 22.4H163.4C171.9 22.4 178.8 29.3 178.8 37.8V48.9Z" fill="white"/>
        <path d="M165.1 75.3H186.2C186.2 81.1 181.5 85.8 175.7 85.8C169.8 85.9 165.1 81.2 165.1 75.3Z" fill="#61D0EC"/>
        <path d="M207.6 75.3H186.2H165.1H143.7C146.3 61.6 157.5 50.9 171.4 49.1C172.8 48.9 174.2 48.8 175.6 48.8C176.7 48.8 177.7 48.9 178.8 49C193.2 50.4 204.9 61.3 207.6 75.3Z" fill="white"/>
        <path d="M186.1 75.3C186.1 81.1 181.4 85.8 175.6 85.8C169.8 85.8 165.1 81.1 165.1 75.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.4 48.9V38.5C171.4 33.8 167.6 29.9 162.8 29.9H117.9C104.4 29.9 93.4998 40.8 93.4998 54.3V229.9H85.5999V53.3C85.5999 36.2 99.3998 22.4 116.5 22.4H163.4C171.9 22.4 178.8 29.3 178.8 37.8V48.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.7 75.3C146.3 61.6 157.5 50.9 171.4 49.1C172.8 48.9 174.2 48.8 175.6 48.8C176.7 48.8 177.7 48.9 178.8 49C193.3 50.4 204.9 61.3 207.6 75.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M207.6 75.3H186.1H165.1H143.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M81.3998 110C80.6998 114.8 80.5998 120.8 79.7998 125.1L80.1998 125.2C80.3998 124.4 80.5998 123.7 80.7998 122.9C80.9998 122.1 81.3998 121.3 82.1998 121C82.6998 120.9 83.1998 121 83.5998 121.4C83.9998 121.7 84.2998 122.2 84.4998 122.7C84.8998 123.7 85.1998 124.8 85.1998 125.9H85.4998C85.4998 120.5 85.4998 114.9 85.4998 111.7C85.5998 103.4 82.3998 103.5 81.3998 110Z" fill="white"/>
        <path d="M80.2998 125.1L79.8998 125C79.5998 126.4 79.2998 127.7 78.8998 128.6C77.8998 130.8 77.1999 132.5 76.5999 134.1L73.3998 133.7L69.5999 146.9V147.2L78.2998 146.9C85.4998 146.9 85.5999 141.7 85.5999 141.7C85.5999 141.7 85.5999 133.8 85.5999 125.8H85.2998C85.2998 124.7 85.0999 123.6 84.5999 122.6C84.3999 122.1 84.0999 121.7 83.6999 121.3C83.2999 121 82.7998 120.8 82.2998 120.9C81.4998 121.1 81.1998 122 80.8998 122.8C80.5998 123.6 80.3998 124.4 80.2998 125.1Z" fill="white"/>
        <path d="M65.7998 141C66.3998 141 66.7998 140.5 66.7998 140C66.7998 139.4 66.2998 139 65.7998 139C65.2998 139 64.7998 139.5 64.7998 140C64.7998 140.5 65.1998 141 65.7998 141ZM60.8998 138.4C60.2998 138.4 59.8998 138.9 59.8998 139.4C59.8998 140 60.3998 140.4 60.8998 140.4C61.3998 140.4 61.8998 139.9 61.8998 139.4C61.8998 138.9 61.3998 138.4 60.8998 138.4ZM69.4998 147.3L32.7998 148.7L38.5998 129.7L73.1998 133.7L69.3998 146.9L69.4998 147.3Z" fill="#61D0EC"/>
        <path d="M60.8999 138.4C60.2999 138.4 59.8999 138.9 59.8999 139.4C59.8999 140 60.3999 140.4 60.8999 140.4C61.3999 140.4 61.8999 139.9 61.8999 139.4C61.8999 138.9 61.3999 138.4 60.8999 138.4Z" fill="#272223"/>
        <path d="M65.7998 140.9C66.3521 140.9 66.7998 140.452 66.7998 139.9C66.7998 139.348 66.3521 138.9 65.7998 138.9C65.2475 138.9 64.7998 139.348 64.7998 139.9C64.7998 140.452 65.2475 140.9 65.7998 140.9Z" fill="#272223"/>
        <path d="M78.2 147C85.4 147 85.5 141.8 85.5 141.8C85.5 141.8 85.5 133.9 85.5 125.9C85.5 120.5 85.5 114.9 85.5 111.7C85.5 103.6 82.3 103.7 81.4 110.1C80.7 114.9 80.6 120.9 79.8 125.2C79.5 126.6 79.2 127.9 78.8 128.8C77.8 131 77.1 132.7 76.5 134.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M85.0999 126.8C85.0999 126.5 85.1999 126.2 85.1999 125.8C85.1999 124.7 84.9999 123.6 84.4999 122.6C84.2999 122.1 83.9999 121.7 83.5999 121.3C83.1999 121 82.6999 120.8 82.1999 120.9C81.3999 121.1 81.0999 122 80.7999 122.8C80.5999 123.5 80.3999 124.3 80.1999 125.1C80.0999 125.6 79.9999 126.2 79.8999 126.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M38.7 129.8L73.2999 133.8L76.4999 134.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M78.1999 147L69.4999 147.3L32.8999 148.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M73.3 133.8L69.5 147" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.4999 142.9L57.8999 142.4" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M68.5 229.8H85.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M102.2 229.8H108.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.7 92.5V106.4" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.6 87.1C190.2 90.4 193.8 93.8 197.4 97.1" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M162.6 85.3C158.7 88.3 154.8 91.3 150.9 94.3" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M147.8 96.2C146.4 97.1 145 97.9 143.6 98.8" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.7 110.1V115.3" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M199.6 99.3L203.1 102.5" stroke="#61D0EC" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Illumination