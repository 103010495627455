import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop"
import ArrowDown from "../components/svg/arrowDown"
import Wall from "../components/svg/wall"
import Magnify from "../components/svg/magnify"
import ThreeArrows from "../components/svg/threeArrows"
import Illumination from "../components/svg/illumination"
import Check from "../components/svg/check"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const HelpPage = () => (

  	<Layout>

      <Seo 
         title="How we help | Media strategy" 
         description="Madcalrity helps marketers know what media value they are achieving through their media spend. We do this through media benchmarking and other media strategy consulting services."
      />

      <section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="400"
                     >How we <span className="blue">help</span></h1>
                     <p
                        data-sal="slide-up" 
                        data-sal-delay="300"
                        data-sal-duration="400"
                     >We help marketers remove guesswork and give them the knowledge and confidence to make better media &amp; advertising decisions.</p>
                     <div className="btn-row"
                        data-sal="slide-up" 
                        data-sal-delay="500"
                        data-sal-duration="400"
                     >
                        <button onClick={() => scrollTo('#anchor')} className="btn-scroll">  
                            <ArrowDown />    
                        </button>
                     </div>
                  </div>
                  <div className="sub-hero-img">
                     <Wall />
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad" id="anchor">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <ThreeArrows />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h2>You <span className="blue">don’t</span> have to guess ... or hope</h2>
                        <p className="lead">Some of the ways we help our clients know what value they are achieving through their media spend and its contribution to the organisation’s bottom-line:</p>
                        <div className="ul-col-2">
                            <ul className="list-unstyled">
                                <li><span className="check"><Check /></span> <Link to="/what-we-do/#consulting" className="a-black no-line">Consulting</Link></li>
                                <li><span className="check"><Check /></span> <Link to="/what-we-do/#benchmarking" className="a-black no-line">Media Benchmarking</Link></li>
                            </ul>
                            <ul className="list-unstyled">
                                <li><span className="check"><Check /></span> <Link to="/what-we-do/#coaching" className="a-black no-line">Coaching</Link></li>
                                <li><span className="check"><Check /></span> <Link to="/what-we-do/#process" className="a-black no-line">Process Evaluation</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section>
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h3><span className="blue">Clarity</span> of thinking</h3>
                        <p className="lead">Or, as we call it – <strong>Madclarity</strong>.</p>
                        <p className="lead">We help our clients cut through tech-driven micro thinking to get to business and brand-driven understanding.</p>
                        <p className="lead">Cutting through the jargon and nonsense to ensure that your on-line strategy is an integral and effective part of your overall comms plan ... with relevant, business success measures.</p>
                        <p className="lead">We give our clients the knowledge and confidence to get the right brief, the best strategy, the most effective buying and the most constructive measurement.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Magnify />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="100"
                           data-sal-duration="300"
                        >
                            <Illumination />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                    >
                        <h4>Data for <span className="blue">illumination</span> ... not support</h4>
                        <p className="lead">Auditing is not Benchmarking. Auditing is only concerned with verifying the past. Madclarity’s Unique <Link to="/what-we-do">Media Benchmarking</Link> is focussed on finding ways to continually improve.</p>
                        <p className="lead">We have taken ‘media auditing’ out of the Stone Age ... moving it from unconstructive, compliance box-ticking to a process that drives understanding and continual improvement ... <strong>improvement of effectiveness, performance and value</strong>.</p>
                        <p className="lead">Data is important to us, but searching for value in that data and making constructive recommendations is our goal ... not using data like a drunk uses a lamppost – for support rather than for illumination.</p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <div className="container section-pad-btm">
         <div className="row">
            <div className="col-middle-wrapper">
               <div className="col-middle">
                  <Link to="/what-we-do" className="btn btn-lg btn-middle">What we do</Link>
               </div> 
            </div>
         </div>
      </div>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                     <Quotes />
                     <div className="test-excerpt">
                        ... as<span className="blue"> opposed to</span> the hype and distortion ...
                     </div>
                     <Testimonial
                        quote="The a2 Milk Company has greatly benefited from the services of Madclarity. Madclarity has stood out as a highly responsive consultancy offering frank, fearless & quality media advice that is unique in my experience, as is it based on hard-nosed analysis as opposed to the hype and distortion that is all too prevalent in the media industry today."
                        name="Peter Nathan"
                        title="Former CEO Asia Pacific, a2 Milk Co."
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>
      
      <Cta />

  </Layout>
)

export default HelpPage