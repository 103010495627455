import React from "react"

const Testimonial = ({ quote, name, title }) => (
    
    <div className="testimonial-wrapper" itemProp="review" itemType="http://schema.org/Review">
        <div className="testimonial">
            <blockquote itemProp="reviewBody">
                {quote}
            </blockquote>
            <div className="person-wrapper flex flex-ac">
                <div className="person-details">
                    <div className="name" itemProp="author">{name}</div>
                    <div className="title-person">{title}</div>
                </div>
            </div>
        </div>
    </div>
)

export default Testimonial