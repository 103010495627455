import React from 'react'
import { Link } from "gatsby"

export default function Cta() {

  return (
    <section className="section-pad">
        <div className="container">
            <div className="row">
                <div className="col-middle-wrapper">
                    <div className="col-middle cta">
                        <h4
                            data-sal="slide-up" 
                            data-sal-delay="200"
                            data-sal-duration="400"
                        >Less complexity, <span className="blue">more clarity</span></h4>
                        <div className="btn-row"
                            data-sal="slide-up" 
                            data-sal-delay="400"
                            data-sal-duration="400"
                        >
                            <Link to="/contact" className="btn btn-lg">Contact us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}