import React from "react"

const Check = () => (
    <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.48 25.06L1.01001 15.48L5.58001 10.48L11.36 15.76L26.28 1.34998L30.99 6.22998L11.48 25.06Z" fill="#58CAE8"/>
        <path d="M11.48 25.06L1.01001 15.48L5.58001 10.48L11.36 15.76L26.28 1.34998L30.99 6.22998L11.48 25.06Z" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1699 20.49L5.1499 16.2" stroke="#231F20" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Check